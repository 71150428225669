<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      route_name: "",
    };
  },
  async mounted() {
    await this.$store.dispatch("user/setUserFromSession");
    this.getLoginCustomerInformation();
    const ip = await this.$helpers.getIpAddress();
    this.setIp(ip);        
  },

  watch: {
    "$route.path": {
      handler(oldUrl, newUrl) {
        this.route_name = this.$route.name;
      },
    },
  },

  methods: {
    getLoginCustomerInformation() {
      var isAuthenticated = this.$store.getters["user/isAuthenticated"];
      if (isAuthenticated) {
        this.axios
          .post("/api/myirc/customer/get-login-customer-information")
          .then((response) => {
            this.$store.commit("user/setCustomerItem", response.data);
          })
          .catch((error) => {
            if (error.response) {
              this.$helpers.catchDataApiAxiosErrors(error, this);
            }
          });
      }
    },
    setIp(ip) {
      this.axios.interceptors.request.use(
        function (config) {
          config.headers.ip = ip;
          return config;
        },
        null,
        { synchronous: true }
      );
    },
  },
};
</script>
