import store from "@/store/";
import { helpers } from "@vuelidate/validators";
import router from "./router/index.js";

const get_ip_counter = 0;

// Add Error Log
const addErrorLog = async function (error, $this) {
  const response = await $this.axios.post(`/api/error/add-log`, {
    error_type: error.response.status,
    referrence_link: $this.refererLink,
    error_page_link: window.location.pathname,
  });

  return response.data.data;
};

// Unauthorized
const is401 = function (error, $this) {
  if (error.response && 401 === error.response.status) {
    //$this.formErrors = error.response.data.errors;
    addErrorLog(error, $this);
    store.commit("user/logout");
    $this.$router.push({ name: "login" });
  }
};

// Captcha Error
const is420 = function (error, $this) {
  if (error.response && 420 == error.response.status) {
    addErrorLog(error, $this);
    $this.toast_notification(error.response.data);
  }
};

// Internal Server Error
const is500 = function (error, $this) {
  if (error.response && 500 == error.response.status) {
    addErrorLog(error, $this);
    $this.toast_notification_500();
    // $this.$router.push({ name: "500" });
  }
};

// Route not found, Data Not Found
const is404 = function (error, $this) {
  if (error.response && 404 === error.response.status) {
    addErrorLog(error, $this);
    $this.$router.push({ name: "error-page" });
  }
};

// 403 Error
const is403 = function (error, $this) {
  if (error.response && 403 === error.response.status) {
    addErrorLog(error, $this).then((res) => {
      //$this.$router.push({ name: "403", params: { unique_id: res.unique_id } });
    });
    //$this.toast_notification(error.response.data);
    //$this.$router.push({ name: "403" });
  }
};

// 403 Error
const is413 = function (error, $this) {
  if (error.response && 413 === error.response.status) {
    //$this.toast_notification(error.response.data);
    addErrorLog(error, $this);
    $this.$router.push({ name: "413" });
  }
};

// Route not found, Data Not Found
const is404OnlyMessage = function (error, $this) {
  if (error.response && 404 === error.response.status) {
    addErrorLog(error, $this);
    $this.toast_notification(error.response.data);
  }
};

// Form Validation Errors
const is422 = function (error, $this) {
  if (error.response && 422 === error.response.status) {
    $this.formErrors = error.response.data.errors;
    if (typeof Object.keys($this.formErrors)[0] != "undefined") {
      scrollToFirstErrorField(Object.keys($this.formErrors)[0]);
    }
  }
};

// Rate Limiter
const is520 = function (error, $this) {
  if (error.response && 520 == error.response.status) {
    addErrorLog(error, $this);
    $this.toast_notification(error.response.data);
  }
};

// MyIRC Maintenance Mode
const isMyIRCMaintenanceMode = function (error, $this) {
  if (error.response && 510 === error.response.status) {
    $this.$router.push({ name: "my-irc-maintenance-mode-page" });
  }
};

// Payment Maintenance Mode
const isPaymentMaintenanceMode = function (error, $this) {
  if (error.response && 510 === error.response.status) {
    $this.$router.push({ name: "payment-maintenance-mode-page" });
  }
};

// New Register Maintenance Mode
const isRegisterMaintenanceMode = function (error, $this) {
  if (error.response && 510 === error.response.status) {
    $this.$router.push({ name: "register-maintenance-mode-page" });
  }
};

const catchAxiosErrors = function (error, $this) {
  is401(error, $this);
  is500(error, $this);
  is404(error, $this);
  is422(error, $this);
  is403(error, $this);
  is413(error, $this);
  is520(error, $this);
};

const catchFormApiAxiosErrors = function (error, $this) {
  is401(error, $this);
  is500(error, $this);
  is403(error, $this);
  is413(error, $this);
  is404(error, $this);
  is420(error, $this);
  is422(error, $this);
  is520(error, $this);
};

const catchAuthFormApiAxiosErrors = function (error, $this) {
  is401(error, $this);
  is500(error, $this);
  is404OnlyMessage(error, $this);
  is420(error, $this);
  is403(error, $this);
  is413(error, $this);
  is422(error, $this);
  is520(error, $this);
};

const catchDataApiAxiosErrors = function (error, $this) {
  is401(error, $this);
  is500(error, $this);
  is403(error, $this);
  is413(error, $this);
  is404(error, $this);
  is520(error, $this);
};

const scrollToFirstErrorField = function (field) {
  //let firstField = errors[0].$property;
  var elmnt = document.getElementById(field);

  if (elmnt != null) {
    var rect = elmnt.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    var offset = {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft,
    };

    setTimeout(() => {
      window.scrollTo(0, offset.top - 100);
    }, 100);

    setTimeout(() => {
      elmnt.focus();
    }, 500);
  }
};

const scrollToTop = function () {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
};

const removeTags = function (str) {
  if (typeof str == "undefined" && (str === null || str === "")) return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
};

const getIpAddress = async function () {
  try {
    if (get_ip_counter <= 3) {      
      const response = await fetch("https://api.ipify.org?format=json", {
        mode: "cors",
      });
      if (!response.ok) throw new Error("Failed to fetch IP address");
      const data = await response.json();
      this.get_ip_counter = 0;
      return data.ip;
    } else {
      this.getIPFromAmazon();
    }
  } catch (error) {
    get_ip_counter++; 
    return this.getIpAddress(); // Added return to avoid multiple parallel retries
  }
};

const getIPFromAmazon = async function () {
  await fetch("https://checkip.amazonaws.com/", { mode: "cors" })
    .then((res) => {
      return data;      
    })
    .then((data) => {
      return data;
    })
    .catch((data) => {
      return data;
    });
};

export default {
  is500,
  is404,
  is403,
  is413,
  is422,
  is401,
  is520,
  is404OnlyMessage,
  catchAxiosErrors,
  catchFormApiAxiosErrors,
  catchAuthFormApiAxiosErrors,
  catchDataApiAxiosErrors,
  isMyIRCMaintenanceMode,
  isPaymentMaintenanceMode,
  isRegisterMaintenanceMode,
  scrollToFirstErrorField,
  scrollToTop,
  removeTags,
  getIpAddress,
  getIPFromAmazon,
};
